import { graphql } from "gatsby"
import React from "react"
import { Body } from "../components/Body"
import { Item } from "../components/Item"
import { Layout } from "../components/Layout/"
import { SEO } from "../components/SEO"

export default ({ data }) => {
  const {
    title,
    body: { json },
    author: {
      name: authorName,
      excerpt: { json: authorExcerpt },
    },
  } = data.article
  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>
      <Body data={json} />
      <Item
        title={`Over de schrijver, ${authorName}`}
        content={authorExcerpt}
      />
    </Layout>
  )
}

export const articleQuery = graphql`
  query articleQuery($slug: String!) {
    article: contentfulArticle(slug: { eq: $slug }) {
      title
      body {
        json
      }
      author {
        name
        excerpt {
          json
        }
      }
    }
  }
`
